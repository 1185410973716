/**=====================
    80. Wishlist CSS start
==========================**/
.wishlist {
  table {
    tr {
      td {
        svg {
          color: $danger-color;
        }
      }
    }
  }
}
/**=====================
    80. Wishlist CSS Ends
==========================**/