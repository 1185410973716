/**====================================
    71. internationalization css start
=====================================**/
.hdg_main {
  position: relative;
}
.main {
  .langChoice {
    position: absolute;
    right: 30px;
    top: 20px;
    padding: 10px 12px;
    background-color: rgba($primary-color, 0.05);
    border-color: $light-gray;
    color: $primary-color;
  }
}
.site{
  .site-bd{
    .masthead{
      .badge{
        padding: 4px 6px 3px;
      }
    }
  }
}
// responsive css
@media (max-width: 991px) {
  .main {
    .langChoice {
      right: 20px;
      top: 11px;
      padding: 8px;
    }
  }
}
@media only screen and (max-width: 575px) {
  .main {
    .langChoice {
      top: 14px;
      padding: 4px 7px;
    }
  }
  .language-xs {
    margin-top: 15px;
  }
}
@media only screen and (max-width: 360px) {
  .language-header {
    padding-bottom: 45px !important;
  }
  .main {
    .langChoice {
      left: 0;
      margin: 0 auto 0 20px;
      top: 50px;
      width: 33%;
      right: 0;
    }
  }
}
/**==================================
    71. internationalization CSS Ends
===================================**/