/**=====================
    85. Typography css start
==========================**/
//Custom css Start
body {
  font-size: $body-font-size;
  overflow-x: hidden;
  font-family: $font-Montserrat;
  color: $theme-body-font-color;
  background-color: $body-color;
}
ul {
  padding-left: $ul-padding-left;
  list-style-type: none;
  margin-bottom: 0;
}
* a {
  color: var(--theme-deafult);
  text-decoration: none;
  letter-spacing: 1px;
}
a {
  &:hover {
    text-decoration: none;
    color: var(--theme-deafult);
  }
  &[class*="badge-"] {
    &:hover {
      color: $white;
    }
  }
}
.btn {
  font-size: 14px;
}
*.btn:focus {
  box-shadow: $btn-focus-box-shadow !important;
}
p {
  font-size: $paragraph-font-size;
  line-height: $paragraph-line-height;
  letter-spacing: $paragraph-letter-spacing;
}
hr {
  background-color: $light-gray;
  opacity: 1;
}
code {
  color: $code-tag-color !important;
  background-color: $code-tag-bg-color;
  padding: $code-tag-padding;
  margin: $code-tag-margin;
  border-radius: $code-tag-border-radious;
  text-transform: none;
}
.d-flex {
  .flex-grow-1 {
    flex: 1;
  }
}
blockquote {
  border-left: $blockquote-border;
  padding: $blockquote-padding;
}
.figure {
  &.text-center {
    blockquote {
      border: none !important;
    }
  }
  &.text-end {
    blockquote {
      border-left: none !important;
      border-right: $blockquote-border;
    }
  }
}
.row {
  > div {
    position: relative;
  }
}
:focus {
  outline-color: $all-focus-outline-color;
}
.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
svg {
  vertical-align: baseline;
}
input {
  &:focus {
    outline-color: $transparent-color;
  }
}
label {
  margin-bottom: 0.5rem;
}
//Typography css start
.typography {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    margin: 20px 0;
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  small {
    padding-left: $small-tag-padding;
    color: $small-tag-color;
  }
}
h2 {
  font-size: 30px;
  font-weight: 700;
}
h3 {
  font-size: 28px;
  font-weight: 700;
}
h4 {
  font-size: 26px;
  font-weight: 700;
}
h5 {
  font-size: 15px;
  font-weight: 600;
}
h6 {
  font-size: 14px;
  font-weight: 700;
}
p {
  font-size: 13px;
  line-height: 1.6;
  font-weight: 500;
}
pre {
  background-color: $pre-tag-bg-color;
  padding: $pre-tag-padding;
}
//Typography css end
.owl-theme {
  .owl-dots {
    .owl-dot {
      span {
        background: lighten($primary-color, 20%);
      }
      &.active,
      &:hover {
        span {
          background: var(--theme-deafult);
        }
      }
    }
  }
  .owl-nav {
    &.disabled {
      & + .owl-dots {
        margin-bottom: -10px;
        margin-top: 25px;
      }
    }
  }
}
.click2edit {
  & ~ .note-editor {
    &.note-frame {
      margin-bottom: 30px;
    }
  }
}
.note-editor {
  &.note-frame {
    border-color: $light-color;
  }
}
.note-editor {
  &.note-frame {
    border-color: $light-color;
  }
}
// modal page
.modal {
  .modal-body {
    .card {
      box-shadow: none !important;
    }
  }
}
.modal-dialog {
  .modal-content {
    .modal-body {
      p {
        a {
          margin-right: 0;
        }
      }
    }
  }
}
// index page notify
.alert-theme {
  button {
    top: 25px !important;
    right: 20px !important;
    color: var(--theme-deafult);
  }
  span {
    + span {
      + span {
        background-color: $white;
        color: var(--theme-deafult);
        border-radius: 4px;
        box-shadow: 0 2px 14px -3px rgba($black, 0.2);
      }
    }
  }
  i {
    padding: 20px;
    padding-right: 0 !important;
    margin-right: 20px !important;
  }
  .progress {
    margin-top: -2px !important;
    height: 2px;
    width: 100%;
    .progress-bar {
      background-color: var(--theme-deafult);
    }
  }
}
.alert-copy {
  border: 1px solid var(--theme-deafult);
  line-height: 1;
  display: inline-block;
  width: 300px;
  color: $white;
  background-color: var(--theme-deafult);
  .close {
    box-shadow: none;
    color: $white;
    line-height: 1.4;
    font-weight: 100;
    &:focus {
      outline: none;
    }
  }
}
.ace-editor {
  height: 400px;
  width: 100%;
}
.editor-toolbar {
  width: 100% !important;
  &.fullscreen {
    z-index: 100;
  }
}
.CodeMirror {
  top: 0 !important;
}
.CodeMirror-fullscreen {
  top: 50px !important;
}
// rangeslider
.irs-from,
.irs-to,
.irs-single {
  background: var(--theme-deafult);
  &:after {
    border-top-color: var(--theme-deafult);
  }
}
.irs-line-mid,
.irs-line-left,
.irs-line-right,
.irs-bar,
.irs-bar-edge {
  background-color: var(--theme-deafult);
}
// sweetalert css
.swal-button {
  background-color: var(--theme-deafult);
  &:active {
    background-color: darken($primary-color, 5%);
  }
}
.swal-button--cancel {
  background-color: #efefee !important;
}
.clipboaard-container {
  h6 {
    line-height: 1.6;
    font-size: $paragraph-font-size;
  }
}
fieldset {
  .bootstrap-touchspin-postfix,
  .bootstrap-touchspin-prefix {
    border-radius: 0;
    padding: 0.4rem 0.75rem;
  }
  .input-group-lg {
    .touchspin {
      height: 50px;
    }
  }
}
.f1{
  .f1-steps{
    .f1-step{
      .f1-step-icon{
        padding: 12px;
      }
    }
  }
}
.form-inline{
  .form-group {
    display: flex;
  }
}
.input-group-text {
  i {
    line-height: 1.5;
  }
}
// animated modal css
.animated-modal {
  .form-select {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
}
.modal-header,
.modal-footer,
.modal-content {
  border-color: $light-gray;
}
/**=====================
    85. Typography css Ends
==========================**/