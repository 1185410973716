/**=====================
     34. Pricing CSS Start
==========================**/
.pricing-content {
  div {
    .pricing-simple,
    .pricing-block {
      margin-bottom: 0;
      box-shadow: 0px 4px 30px rgba(95, 94, 231, 0.07);
    }
    .pricing-simple {
      .btn-block {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-radius: 20px;
      }
    }
  }
}
.pricing-block {
  overflow: hidden;
  .pricing-header {
    position: relative;
    height: 170px;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0% 50% 0% 50%;
      background-color: rgba($primary-color, 0.05);
    }
    h2 {
      color: $primary-color;
      font-weight: 600;
      font-size: 30px;
      text-transform: uppercase;
      margin-top: 30px;
      span {
        font-size: 50%;
      }
    }
    .price-box {
      background-color: $primary-color;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      margin-left: auto;
      margin-right: auto;
      transform: rotate(45deg);
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      bottom: -40px;
      left: 0;
      right: 0;
      > div {
        transform: rotate(-45deg);
      }
      h3 {
        color: $white;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 1;
        font-size: 24px;
      }
      p {
        color: $white;
        text-transform: capitalize;
        line-height: 1;
        font-weight: 600;
      }
    }
  }
  svg {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    margin-left: -1px;
  }
  .pricing-list {
    padding-top: 90px;
    padding-bottom: 30px;
    ul {
      padding: 0;
      li {
        margin-bottom: 20px;
        h6 {
          font-weight: 500;
          margin-bottom: 0;
          span {
            color: $light-text;
            margin-left: 5px;
            display: inline-block;
          }
        }
      }
    }
    .pricing-inner {
      margin-bottom: 30px;
    }
  }
}
.pricing-simple {
  .card-body {
    border-bottom: none;
    h3 {
      position: relative;
      margin-bottom: 38px;
      font-weight: 600;
      &:before {
        position: absolute;
        content: "";
        width: 80px;
        height: 1px;
        margin: 0 auto;
        left: 0;
        right: 0;
        bottom: -10px;
        background-color: $light-text;
      }
    }
    h1 {
      font-size: 80px;
      line-height: 0.9;
      margin-bottom: 30px;
      font-weight: 600;
      color: $primary-color;
    }
    h6 {
      color: $light-text;
      font-weight: 500;
    }
  }
}
@media only screen and (max-width: 1550px) {
  .pricing-block {
    .pricing-header {
      height: 150px;
      h2 {
        font-size: 22px;
      }
    }
  }
}
@media only screen and (max-width: 1470px) {
  // pricing page //
  .pricing-simple{
    .card-body{
      h1{
        font-size: 70px;
      }
    }
  }
  .pricing-col {
    margin-bottom: -30px;
    > div {
      margin-bottom: 30px;
    }
  }
  .pricing-block {
    .pricing-header {
      h2 {
        font-size: 26px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1199px) {
  .pricing-simple{
    .card-body{
      h3{
        font-size: 24px;
      }
    }
  }
  .pricing-block {
    .pricing-header{
      h2{
        font-size: 22px;
      }
    }
    .ribbon-vertical-left {
      left: 3px;
      top: 0px;
    }
    .ribbon-bookmark {
      top: 0;
      left: -1px;
    }
    .ribbon-clip-right {
      top: 0;
    }
  }
}
@media only screen and (max-width: 1199px) {
  .pricing-block {
    .pricing-inner {
      h3 {
        font-size: 20px;
      }
      ul {
        li {
          h6 {
            font-size: 14px;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .pricing-col {
    margin-bottom: -20px;
    > div {
      margin-bottom: 20px;
    }
  }
  .pricing-content {
    &.price-simple{
      .row{
        margin-bottom: -20px;
      }
    }
    div {
      &:nth-child(2),
      &:first-child {
        .pricing-simple {
          margin-bottom: 20px;
        }
      }
    }
  }
  .pricing-block {
    .pricing-inner {
      padding: 20px 0 20px;
      ul,
      h3 {
        margin-bottom: 1px;
      }
    }
  }
  .pricing-simple {
    .card-body {
      h1 {
        margin-bottom: 20px;
      }
      h3 {
        margin-bottom: 28px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .pricing-content {
    div {
      &:last-child {
        .pricing-block {
          margin-bottom: 0;
        }
      }
    }
  }
  .pricing-block {
    .pricing-inner {
      padding: 15px 0 15px;
      h3 {
        font-size: 28px;
      }
      ul {
        li {
          h6 {
            font-size: 16px;
          }
        }
      }
    }
    .pricing-header {
      h2 {
        font-size: 24px;
      }
    }
  }
  .pricing-simple {
    .card-body {
      h1 {
        font-size: 60px;
      }
      h3 {
        font-size: 20px;
      }
    }
  }
}
@media only screen and (max-width: 575px) {
  .pricing-content {
    &.price-simple{
      .row{
        margin-bottom: 0;
      }
    }
    div {
      div {
        .pricing-simple {
          margin-bottom: 15px;
        }
        &:last-child {
          .pricing-simple {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .pricing-simple {
    .card-body {
      h1 {
        margin-bottom: 15px;
        font-size: 45px;
      }
    }
  }
  .pricing-block {
    .pricing-inner {
      padding: 15px 0 15px;
    }
  }
  .pricing-col {
    > div {
      width: 100%;
    }
  }
  .pricing-block {
    .pricing-list {
      .btn {
        padding: 5px 15px;
        font-size: 16px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .pricing-block {
    .pricing-list {
      padding-top: 80px;
      ul {
        li {
          margin-bottom: 15px;
          h6 {
            font-size: 14px;
          }
        }
      }
      .pricing-inner {
        margin-bottom: 25px;
      }
    }
  }
}
@media only screen and (max-width: 420px) {
  .pricing-block {
    .pricing-header {
      height: 140px;
      .price-box {
        width: 80px;
        height: 80px;
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}
/**=====================
    34. Pricing CSS Ends
==========================**/