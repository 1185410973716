/**=====================
     29. Form Input CSS Start
==========================**/
%input-mixin1 {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}
%input-mixin2 {
  position: absolute;
  z-index: 2;
  left: 10px;
  font-size: 14px;
  width: 20px;
  margin-left: -2.5px;
  text-align: center;
  padding: 10px 0;
  top: 1px;
}
.input-group-square {
  .input-group-text,
  .form-control {
    border-radius: 0;
  }
}
.input-group-solid {
  .input-group-text,
  .form-control {
    background: $light-color;
  }
}
.input-group-air {
  box-shadow: 0 3px 20px 0 $light-semi-gray;
}
.icon-addon {
  position: relative;
  color: $color-scarpa;
  display: block;
  &:after {
    display: table;
    content: "";
    clear: both;
  }
  &:before {
    display: table;
    content: "";
  }
  .addon-md {
    .form-control {
      padding-left: 30px;
      float: left;
      font-weight: normal;
    }
    .glyphicon {
      @extend %input-mixin1;
    }
    .fa {
      @extend %input-mixin1;
    }
  }
  .form-control {
    padding-left: 30px;
    float: left;
    font-weight: normal;
  }
  .glyphicon,
  .fa {
    @extend %input-mixin2;
  }
}
.pill-input-group span {
  &:last-child {
    border-top-right-radius: 1.3rem;
    border-bottom-right-radius: 1.3rem;
  }
  &:first-child {
    border-top-left-radius: 1.3rem;
    border-bottom-left-radius: 1.3rem;
  }
}
/**=====================
     29. Form Input CSS Ends
==========================**/