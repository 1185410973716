/*! -----------------------------------------------------------------------------------

    Template Name: Tivo Admin
    Template URI: http://admin.pixelstrap.com/Tivo/theme
    Description: This is Admin theme
    Author: Pixelstrap
    Author URI: https://themeforest.net/user/pixelstrap

-----------------------------------------------------------------------------------
01. Update CSS
02. Reset CSS
03. Card CSS
04. Loader CSS
05. Header CSS
06. Button CSS
07. Color CSS
08. GRID CSS
09. Error-page CSS
10. Icon css
11. Form Builder 1 CSS
12. Form Builder 2 CSS
13. Email Application  CSS
14. Chat CSS
15. Alert CSS
16. Popover CSS
17. List CSS
18. Switch  CSS
19. Mega option CSS
20. Touchspin  CSS
21. Select 2 CSS
22. User profile css
23. Progress CSS
24. According CSS
25. Authentication CSS
26. Form CSS
27. Coming Soon CSS
28. Radio CSS
29. Form Input CSS
30. Data-table CSS
31. JS datagrid CSS
32. Page CSS
33. Ecommerce CSS
34. Pricing CSS
35. Form builder css
36. NAV CSS
37. Dropdown CSS
38. Landing CSS
39. Table  CSS
40. File Manager
41. Chart CSS
42. Timeline CSS
43. Timeline 2 CSS
44. Tabs CSS
45. Gallery CSS
46. Blog CSS
47. Footer CSS
48. Form Wizard
49. Badge CSS
50. Tree CSS
51. Search CSS
52. Datepicker CSS
53. Dashboard CSS
54. Tour CSS
55. theme layout CSS
56. Ribbon CSS
57. breadcrumb CSS
58. Steps  CSS
59. Customizer CSS
60. RTL CSS
61. base_inputs CSS
62. Responsive css
63. Box-layout css
64. Button Builder css
65. Button Builder color picker CSS
66. Dashboard 2
67. Invoice
68. Faq CSS
69. Job CSS
70. knowledgebase CSS
71. internationalization CSS
72. Learning CSS
73. Order History css
74. Print CSS
75. Sidebar CSS
76. social app
77. avatars
78. Bookmark
79. cart
80. wishlist
81. checkout
82. bookmark app
83. contacts
84. task
85. Typography css
86. Scrollbar css
87. typahead-search CSS
88. general widget css
89. Project list css
90. Calender css
*/
// *** utils ***
@import "utils/variables";
@import "utils/theme-font";
:root {
    --theme-deafult:#5c61f2;
    --theme-secondary: #eeb82f;
}
// *** base ***
@import "base/reset";
@import "base/typography";
//  *** components ***
@import "components/according.scss";
@import "components/alert.scss";
@import "components/avatars.scss";
@import "components/badge.scss";
@import "components/bookmark.scss";
@import "components/breadcrumb.scss";
@import "components/builders.scss";
@import "components/buttons.scss";
@import "components/card.scss";
@import "components/color.scss";
@import "components/datatable.scss";
@import "components/datepicker.scss";
@import "components/dropdown.scss";
@import "components/form-builder.scss";
@import "components/form-input.scss";
@import "components/form-wizard";
@import "components/forms.scss";
@import "components/form_builder-2.scss";
@import "components/icons.scss";
@import "components/list.scss";
@import "components/loader.scss";
@import "components/popover.scss";
@import "components/print.scss";
@import "components/radio.scss";
@import "components/ribbon.scss";
@import "components/switch.scss";
@import "components/tab.scss";
@import "components/table.scss";
@import "components/touchspin.scss";
@import "components/tour.scss";
@import "components/tree.scss";
@import "components/typeahead-search.scss";
@import "components/scrollbar";
@import "components/select2.scss";
@import "components/calender.scss";
//	*** pages ***
@import "pages/blog.scss";
@import "pages/bookmark-app.scss";
@import "pages/cart.scss";
@import "pages/chart.scss";
@import "pages/chat.scss";
@import "pages/checkout.scss";
@import "pages/comingsoon.scss";
@import "pages/contacts.scss";
@import "pages/dashboard_default.scss";
@import "pages/dashboard_2.scss";
@import "pages/general-widget.scss";
@import "pages/ecommerce.scss";
@import "pages/email-application.scss";
@import "pages/errorpage.scss";
@import "pages/faq.scss";
@import "pages/file.scss";
@import "pages/gallery.scss";
@import "pages/internationalization.scss";
@import "pages/job-search.scss";
@import "pages/jsgrid.scss";
@import "pages/kanban.scss";
@import "pages/knowledgebase.scss";
@import "pages/landing.scss";
@import "pages/landing-responsive.scss";
@import "pages/language.scss";
@import "pages/learning.scss";
@import "pages/login.scss";
@import "pages/megaoption.scss";
@import "pages/order-history.scss";
@import "pages/page.scss";
@import "pages/pricing.scss";
@import "pages/progress.scss";
@import "pages/projectlist.scss";
@import "pages/social-app.scss";
@import "pages/task.scss";
@import "pages/timeline-v.scss";
@import "pages/timeliny.scss";
@import "pages/user-profile.scss";
@import "pages/wishlist.scss";
@import "pages/invoice.scss";
// *** layout *** 
@import "layout/footer.scss";
@import "layout/grid.scss";
@import "layout/header.scss";
@import "layout/navs.scss";
@import "layout/search.scss";
@import "layout/sidebar.scss";
@import "layout/box-layout.scss";
/* ---------------------
	*** themes ***
-----------------------*/
@import "themes/dark.scss";
@import "themes/theme-customizer.scss";
@import "themes/update.scss";
@import "themes/rtl.scss";